import styled from 'styled-components'

export const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 400px;
    padding: 0 5px;
    width: 100%;
    position: relative;
    z-index: 1;
    font-family: Poppins;
`
export const AboutBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: bottom;
`
export const AboutWrapper = styled.div`
    margin-top: 40px;
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    justify-content: center;
`

export const TextWrapper = styled.div`
    z-index: 10;
    color: #ECECEC;
    text-align: center;
    max-width: 600px;
    margin-top: 200px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.04em;
`

export const TopLine = styled.p`
    z-index: 10;
    color: #C3423F;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.07em !important;
    position: absolute;
    align-items: start;
    margin-left: 40px;
    margin-top: 20px;

`


export const RegLink = styled.a`
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:  #C3423F;
    cursor: pointer;

    &:hover {
        color: #ECECEC;
        transition: 0.2s ease-in-out;
    }
`

