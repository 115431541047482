import React from 'react';
import Hyphenated from 'react-hyphen';
import {RegLink} from './ContactElements.js'

function ContactText() {
  return (
    <div>
      <Hyphenated>
        <p>
          I look forward to reading your emails.
          Send them <RegLink href="mailto:rodrigkim@gmail.com">here</RegLink>.
        </p>
      </Hyphenated>
    </div>
    )
}

export default ContactText
