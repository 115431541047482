import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import NavbarLayout from './NavbarLayout';

function App() {
  return (
    <div className="App">
      <div>
        <div>
          <Router>
            <Routes>
              <Route path='/' element={<NavbarLayout/>}/>
              
            </Routes>
          </Router>
          
        </div>
      </div>
    </div>
  );
}

export default App;
