import React from 'react'
import {
    ProjectContainer,
    ProjectWrapper,
    ProjectGrid,
    TopLine,
    Grain,
    ProjectBg
} from './ProjectElements'
import ProjectTitle from './ProjectTitle.js'
import ProjectCard from './ProjectCard.js'

import cheznous_gif from '../assets/cngif.gif'
import matinee_gif from '../assets/matineegif.gif'

const ProjectSection = () => {
    return (
        <div>
            <ProjectContainer id="projects">
                <ProjectBg>   
                    <Grain/>
         
                </ProjectBg>
                <ProjectWrapper>
                            <TopLine>
                                <ProjectTitle/>
                            </TopLine>
                    <ProjectGrid>
                        <ProjectCard url="http://www.cheznousnyc.com" gif={cheznous_gif} title="chez nous." description="An interactive website for a pop-up restaurant, with mailchimp integration and a back-end to handle reservations. Built with React and Ruby on Rails."/>
                        <ProjectCard url="http://www.matineechocolate.com" gif={matinee_gif} title="matinée chocolate." description="A landing page for a Brooklyn-based chocolate company. Built with React."/>
                    </ProjectGrid>
                </ProjectWrapper>
            </ProjectContainer>
        </div>
    )
}

export default ProjectSection