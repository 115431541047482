import React from 'react'
import {
    AboutContainer,
    AboutBg,
    ImageBg,
    AboutWrapper,
    FormWrapper,
    TextWrapper,
    TopLine,
    BtnWrap,
} from './ContactElements'
import ContactText from './ContactText'
import bgImage from '../assets/sidebar.jpg'
import ContactTitle from './ContactTitle'

const ContactSection = () => {
  return (
    <div>
        <AboutContainer id="contact">
            <AboutBg>
                <ImageBg src={bgImage} />
            </AboutBg>
            <AboutWrapper>
                <TopLine>
                    <ContactTitle/>
                </TopLine>
                <TextWrapper>
                    <ContactText/>
                </TextWrapper>
            </AboutWrapper>
        </AboutContainer>
    </div>
  )
}

export default ContactSection