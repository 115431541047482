import styled from 'styled-components'
import {Link} from 'react-scroll'
import {MdKeyboardArrowRight, MdArrowForward, MdKeyboardArrowLeft, MdArrowBack} from 'react-icons/md';


export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? "#695f5f" : "#010606")};
    opacity: 90%;
    white-space: nowrap;
    padding: ${({big}) => (big ? "14px 48px" : "12px 30px")};
    color: ${({dark}) => (dark ? "#19140D" : "#fff")};
    font-size: ${({fontBig})=> (fontBig ? "20px" : "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? "#19140D" : "#fff")};
    }
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowBack = styled(MdArrowBack)`
    margin-left: 8px;
    font-size: 20px;
`
