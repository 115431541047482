import styled from 'styled-components'
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

export const ProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: #dfdfdf;
    color: #040404;
    font-family: Poppins;
    `

export const Grain = styled.div`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    pointer-event: none;
    z-index: 0;
    transform: translateZ(0);
    
        &:before {
            content: "";
            top: -10rem;
            left: -10rem;
            width: calc(100% + 20rem);
            height: calc(100% + 20rem);
            z-index: 9999;
            position: fixed;
            background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/5c/Image_gaussian_noise_example.png);
            opacity: 0.15;
            pointer-events: none;
            -webkit-animation: noise 1s steps(2) infinite;
            animation: noise 1s steps(2) infinite;
        }

        @-webkit-keyframes noise {
            to { transform: translate3d(-7rem,0,0) }
        }

        @keyframes noise {
            0% { transform: translate3d(0,9rem,0) }
            10% { transform: translate3d(-1rem,-4rem,0) }
            20% { transform: translate3d(-8rem,2rem,0) }
            30% { transform: translate3d(9rem,-9rem,0) }
            40% { transform: translate3d(-2rem,7rem,0) }
            50% { transform: translate3d(-9rem,-4rem,0) }
            60% { transform: translate3d(2rem,6rem,0) }
            70% { transform: translate3d(7rem,-8rem,0) }
            80% { transform: translate3d(-9rem,1rem,0) }
            90% { transform: translate3d(6rem,-5rem,0) }
            to { transform: translate3d(-7rem,0,0) }
        }
    }
`

export const AboutBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const ProjectBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 0 8px gray;
    `

export const ProjectWrapper = styled.div`
    margin-top: 40px;
    padding-bottom: 50px;
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
`

export const ProjectGrid = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    padding-bottom: 50px;
    margin-top: 110px;

`


export const TextWrapper = styled.div`
    z-index: 10;
    color: #FFDEAA;
    text-align: left;

`

export const TopLine = styled.div`
    z-index: 10;
    color: #040404;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
    letter-spacing: -0.07em !important;
    position: absolute;
    align-items: start;
    margin-left: 40px;
    margin-top: 20px;

`

export const Title = styled.div`
    padding-top: 40px;
    font-weight: 700;
    font-size: 30px;
    flex-direction: row;
    letter-spacing: -0.07em !important;
`

export const GifWrapper = styled.div`
    position: relative;
    height: 310px;
    width: 490px;

    @media screen and (max-width: 580px){
        height: 207px;
        width: 327px;
    }
`

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    z-index: 10;
`

export const IconWrapper = styled.div`
    background: transparent;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
`

export const LinkArrow = styled(FaArrowUpRightFromSquare)`
    color: #040404
    font-size: 0.5em;
`

export const Description = styled.div`
    width: 490px;
    justify-text: center;
    padding-top: 20px;
    padding-bottom: 50px;
    letter-spacing: -0.06em !important;

    @media screen and (max-width: 580px){
        width: 327px;
    }
`

export const LinkText = styled.div`
`