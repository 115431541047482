import React from 'react'
import {
    GifWrapper,
    ImageBg,
    CardWrapper,
    Title,
    LinkArrow,
    IconWrapper,
    Description,
    LinkText
} from './ProjectElements'

function ProjectCard({url, gif, title, description}) {
    
    function handleClick(){
        window.open(url)
    }

    return (
        
        <CardWrapper>
            <div onClick={()=> handleClick()} style={{cursor : 'pointer'}}>
                <GifWrapper>
                    <ImageBg src={gif}/>
                </GifWrapper>
                <Title>  
                    {title} 
                            <LinkText>
                            {/* <IconWrapper>
                                <LinkArrow/>
                            </IconWrapper> */}
                            </LinkText>
                </Title>
                <Description> {description}
                </Description>
            
            </div>
        </CardWrapper>
    )
}

export default ProjectCard