import React, {useEffect, useState} from "react";
import {FaBars} from "react-icons/fa"
import { NavLink } from "react-router-dom";
import {animateScroll as scroll} from 'react-scroll'
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    RegLink,
    NavBg,
    ImageBg
} from "./NavbarElements"


function Navbar({toggle}) {
    const [scrollNav, setScrollNav] = useState(0);

    const changeNav = () => {
        if (window.scrollY >= 100) {
            setScrollNav(1);
        } else {
            setScrollNav(0);
        }
    };

    useEffect(()=> {
        window.addEventListener('scroll', changeNav);
        console.log(scrollNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <> 
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        {/* <NavBg>
                            <ImageBg src={navbanner}/>
                        </NavBg> */}
                        <NavLogo scrollNav={scrollNav} smooth spy to='/' onClick={toggleHome}>
                            <p>kr.</p>
                        </NavLogo>
                        <MobileIcon scrollNav={scrollNav} onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact={true} offset={-80}  to='projects'>projects</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact={true} offset={-80} to='contact'>contact</NavLinks>
                            </NavItem>

                        </NavMenu>
                    </NavbarContainer>
                </Nav>

            

        </>
    )
    }
    
    export default Navbar;