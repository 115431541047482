import React from 'react'
import { TypeAnimation } from 'react-type-animation'

function Intro() {
    const typeText = [
        "developer.", 
        2500,
        "freelancer.", 
        2500,
        "restaurateur.", 
        2500,
    ]

    return (
        <div>
            <TypeAnimation 
                preRenderFirstString={true}
                sequence={typeText}
                speed={40}
                style={{fontSize: '1em'}}
                wrapper="span"
                repeat={Infinity}
            />
        </div>
    )
}

export default Intro