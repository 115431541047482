import React from 'react'
import { TypeAnimation } from 'react-type-animation'

function ContactTitle() {
    const typeText = [
        "contact.", 
        5300,
        "write to me.", 
        5300
    ]

    return (
        <div>
            <TypeAnimation 
                sequence={typeText}
                speed={40}
                style={{fontSize: '1em'}}
                wrapper="span"
                repeat={Infinity}
            />
        </div>
    )
}

export default ContactTitle