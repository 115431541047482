import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link as LinkS} from 'react-scroll'


export const SidebarContainer = styled.aside`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen })=> (isOpen ? '1' : '0')};
    z-index: ${({ isOpen })=> (isOpen ? '999' : '-10')};
`
export const SidebarBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
`
export const CloseIcon = styled(FaTimes)`
    color: #C3423F

    
`

export const Icon = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 1.7rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
export const SidebarWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
`

export const SidebarContent = styled.div`

`

export const SidebarMenu = styled.ul`
    padding-left: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 130px);
    text-align: center;
    
    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(7, 130px)
    }
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    font-family: Poppins;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    list-styled: none;
    transition: 0.2s ease-in-out;
    color:  #C3423F;
    cursor: pointer;
    letter-spacing: -0.05em;


    &:hover {
        color: #ECECEC;
        transition: 0.2s ease-in-out;
    }
`

export const RegLink = styled.a`
    display: flex;
    font-family: Poppins;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    color:  #C3423F;
    cursor: pointer;
    

    &:hover {
        color: #ECECEC;
        transition: 0.2s ease-in-out;
    }
`
