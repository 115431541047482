import { Outlet } from "react-router-dom";
import React, {useState} from "react";
import HeroSection from "./HeroSection/HeroSection";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

import ProjectSection from "./ProjectSection/ProjectSection";
import ContactSection from "./ContactSection/ContactSection";

const NavbarLayout = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        console.log(isOpen)
        setIsOpen(!isOpen)
    }    
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection/> 
            <ProjectSection/>
            <ContactSection/>

            {/* <Footer /> */}
        </div>
    );
};

export default NavbarLayout;