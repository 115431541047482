import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarLink, 
    SidebarWrapper,
    SidebarMenu,
    RegLink,
    SidebarBg,
    ImageBg,
    SidebarContent,
} from './SidebarElements'
import bgImage from "../assets/sidebar.jpg"


const Sidebar = ({toggle, isOpen}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>

        <SidebarBg>
            <ImageBg src={bgImage}/>
        </SidebarBg>
        <SidebarContent>

            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
        <SidebarWrapper>
            <SidebarMenu>

                {/* <SidebarLink onClick={toggle} offset={-80} to="gallery">
                    gallery
                </SidebarLink> */}
                <SidebarLink onClick={toggle} offset={-80} to="projects">
                    projects
                </SidebarLink>
                <SidebarLink onClick={toggle} offset={-80} to="contact">
                    contact
                </SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
                </SidebarContent>
    </SidebarContainer>
  )
}

export default Sidebar