import React from 'react'
import { TypeAnimation } from 'react-type-animation'

function ProjectTitle() {
    const typeText = [
        "projects.", 
        5800,
        "some of my work.", 
        5800
    ]

    return (
        <div>
            <TypeAnimation 
                sequence={typeText}
                speed={40}
                style={{fontSize: '1em'}}
                wrapper="span"
                repeat={Infinity}
            />
        </div>
    )
}

export default ProjectTitle