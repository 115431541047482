import React, {useState} from 'react'
import { 
    HeroContainer, 
    HeroBg, 
    ImageBg,
    HeroContent,
    HeroH1,
    HeroP,
    TextWrapper
} from './HeroElements'
import {Button, ArrowForward, ArrowRight} from '../ButtonElement'
import bgImage from "../assets/bgImage.jpg"
import Intro from './Intro'

const HeroSection = () => {
    const [hoverRes, setHoverRes] = useState(false)
    const [hoverIG, setHoverIG] = useState(false)

    const onHoverRes = () => {
        setHoverRes(!hoverRes)
    }
    const onHoverIG = () => {
        setHoverIG(!hoverIG)
    }

  return (
    <HeroContainer id="home">
        <HeroBg>
            <ImageBg src={bgImage}/>
        </HeroBg>
            <HeroContent>
                <TextWrapper>
                    
                    <HeroH1>              
                        kim rodrig
                    </HeroH1>
                    <HeroP>
                        <Intro/>
                    </HeroP>
                </TextWrapper>
            </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection