import styled from 'styled-components'

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 900px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: black;
    

    @media screen and (min-width: 1000px){
        justify-content: left;
    }

`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 10;
    
    @media screen and (min-width: 1000px){
        margin-left: 20px;
    }
    @media screen and (min-width: 1300px){
        margin-left: calc(calc(100vw - 1220px)/4);
    }
    

`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
`


export const HeroContent = styled.div`
    z-index: 1;
    margin-top: -10px;
    max-width: 1200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1000px){
        position: absolute;
        align-items: center;
        margin-left: 30px;
        justify-content: left;
    }
`

export const HeroH1 = styled.h1`
    color: #C3423F;
    font-size: 100px;
    font-family: 'Poppins';
    text-align: center;
    max-width: 600px;
    letter-spacing: -0.08em !important;
    font-weight: 700;
    line-height: 0.8em;


    @media screen and (max-width: 668px){
        margin-top: -150px;
        font-size: 60px;
    }

    @media screen and (min-width: 1000px){
        margin-top: -400px;
        font-size: 100px;
        text-align: left;
    }

`

export const HeroP = styled.p`
    color: #C3423F;
    font-size: 50px;
    font-family: 'Poppins';
    text-align: center;
    max-width: 600px;
    line-height: 1.8em;
    font-weight: bold;
    letter-spacing: -0.09em !important;
    margin-top: -90px;
    margin-left: 3px;

    @media screen and (max-width: 668px){
        font-size: 36px;
        margin-top: -55px;
    }

    @media screen and (min-width: 1000px){

    }

`

export const HeroBtnWrapper = styled.div`
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
